.iko-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
}

.iko-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large;
}
.iko-description-div {
  margin-top: 180px;
  padding: 40px 0px;
}
