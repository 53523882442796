.featured-items-header {
  cursor: pointer;
  text-align: center;
  color: white;
}

.featured-items-header-active {
  border-bottom: 2px white solid;
  cursor: pointer;
  text-align: center;
  color: white;
}
