.nod-about-page-first-section {
  margin-top: 180px;
  padding: 50px 0px;
  background-color: white;
}
.nod-about-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
}
.nod-about-text {
  padding-right: 20%;
  color: #717171;
  font-size: small;
}
