@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
.text_val {
  text-transform: uppercase; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  height: auto;
  background-color: #0072bc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header .header-main {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 29px 0px; }
  .header .logo-container {
    display: flex;
    justify-content: flex-start; }
  .header .user-action i {
    position: absolute;
    right: 15px;
    font-size: 28px;
    color: #fff; }
  .header .header-secondary {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #00619f; }
    .header .header-secondary > div {
      flex-grow: 1; }
  .header .nav-container .navMenu,
  .header .language-selection-container .navMenu {
    padding: 0px;
    margin: 0px;
    width: 100%;
    list-style: none;
    position: relative;
    margin-bottom: 0px !important; }
    .header .nav-container .navMenu li,
    .header .language-selection-container .navMenu li {
      display: inline;
      float: left;
      position: relative; }
      .header .nav-container .navMenu li a,
      .header .language-selection-container .navMenu li a {
        text-decoration: none;
        padding: 13px 20px;
        color: #ffffff;
        float: left;
        text-align: center;
        font-size: 12px; }
  .header .language-selection-container .navMenu {
    display: flex;
    justify-content: flex-end; }
  .header .search-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .header .search-container .test-class {
      display: flex;
      border: 0px !important;
      height: 38px !important;
      width: 93%; }
      .header .search-container .test-class input {
        font-size: 14px;
        padding: 0px 10px; }
      .header .search-container .test-class button {
        outline: none;
        border: none;
        padding: 0px 12px; }
    .header .search-container .suggestions-container {
      display: flex;
      position: absolute;
      height: 520px;
      top: 39px;
      width: 100%;
      min-height: 80px;
      background-color: #fff;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.275);
      border: 1px solid #d8d8d8;
      z-index: 9; }
      .header .search-container .suggestions-container .suggestions {
        flex-basis: 40%;
        /*border-right: 1px solid #D8D8D8;*/
        text-align: left;
        padding: 10px;
        overflow-y: scroll; }
      .header .search-container .suggestions-container .recommendation {
        flex-basis: 60%;
        text-align: left;
        padding: 10px;
        overflow-y: scroll; }
      .header .search-container .suggestions-container .title {
        font-weight: bold;
        color: #6a6a6a;
        font-size: 13px; }
      .header .search-container .suggestions-container .title-container {
        border-bottom: 1px solid;
        border-color: #d8d8d8; }
      .header .search-container .suggestions-container .recommendation_add_qty {
        width: 50px;
        border: 1px solid #ccc;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0px;
        height: 30px; }
      .header .search-container .suggestions-container .typeahead-text {
        color: #6a6a6a;
        font-size: 13px;
        margin: 0px;
        padding: 5px 0px;
        cursor: pointer; }
        .header .search-container .suggestions-container .typeahead-text:hover {
          background-color: #f8f8f8;
          font-weight: bold; }
      .header .search-container .suggestions-container .recommended-item {
        border-bottom: 1px solid #d8d8d8;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .header .search-container .suggestions-container .recommended-item .item-description {
          color: #6a6a6a;
          font-size: 13px;
          flex-basis: 50%; }
        .header .search-container .suggestions-container .recommended-item .item-quantity {
          color: #979797;
          font-size: 10px;
          border: 1px solid #d8d8d8;
          padding: 5px; }
        .header .search-container .suggestions-container .recommended-item button {
          border: 0;
          outline: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          padding: 6px 13px;
          background-color: #00619f;
          color: #fff; }
  .header .react-search-field-input {
    height: 38px !important; }
  .header .react-search-field-button {
    height: 38px !important;
    background-color: #00619f !important;
    color: #fff !important; }
  .header .react-search-field-button svg {
    fill: #fff !important; }
  .header .text_style {
    color: #fff;
    padding-right: 7px;
    font-size: 12px; }
  .header .background-darkblue {
    background-color: #00619f; }
  .header .col:first-child {
    margin-left: 0; }
  .header .span_6_of_12 {
    width: 49.2%; }
  .header .remove-top-bottom {
    margin: 0 0 0 1.6%;
    width: 72% !important; }
  .header .col {
    display: grid;
    float: left; }
  .header .active {
    /* background-color: #0072bc; */
    background-color: #b91e1e;
    padding: 18px 20px !important; }
  .header .inactive {
    background-color: #00619f;
    padding: 18px 20px !important; }
  .header .select-style {
    background: url(/assets/images/arrow-down.png) no-repeat 90% 65%;
    padding-top: 12px;
    padding-left: 12px; }
  .header .select-style select {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    width: 20%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none; }
  .header option {
    font-weight: normal;
    display: block;
    white-space: pre;
    min-height: 1.2em;
    padding: 0px 2px 1px; }

::-webkit-input-placeholder {
  text-transform: capitalize; }

::placeholder {
  text-transform: capitalize; }

.text_val {
  text-transform: uppercase; }

.on_focused input:focus {
  outline: none; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  height: auto;
  background-color: #0072bc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header .header-main {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px; }
  .header .logo-container {
    display: flex;
    justify-content: flex-start; }
  .header .user-action i {
    position: relative;
    right: 0px;
    font-size: 28px;
    color: #fff; }
  .header .header-secondary {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #00619f; }
    .header .header-secondary > div {
      flex-grow: 1; }
  .header .nav-container .navMenu,
  .header .language-selection-container .navMenu {
    padding: 0px;
    margin: 0px;
    width: 100%;
    list-style: none;
    position: relative;
    margin-bottom: 0px !important; }
    .header .nav-container .navMenu li,
    .header .language-selection-container .navMenu li {
      display: inline;
      float: left;
      position: relative; }
      .header .nav-container .navMenu li a,
      .header .language-selection-container .navMenu li a {
        text-decoration: none;
        padding: 13px 20px;
        color: #ffffff;
        float: left;
        text-align: center;
        font-size: 12px; }
  .header .language-selection-container .navMenu {
    display: flex;
    justify-content: flex-end; }
  .header .search-container {
    position: relative;
    display: flex;
    flex-direction: column; }
    .header .search-container .test-class {
      display: flex;
      border: 0px !important;
      height: 38px !important;
      width: 100%; }
      .header .search-container .test-class input {
        font-size: 14px;
        padding: 0px 10px; }
      .header .search-container .test-class button {
        outline: none;
        border: none;
        padding: 0px 12px; }
    .header .search-container .suggestions-container {
      display: flex;
      position: absolute;
      height: 520px;
      top: 39px;
      width: 100%;
      min-height: 80px;
      background-color: #fff;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.275);
      border: 1px solid #d8d8d8;
      z-index: 9; }
      .header .search-container .suggestions-container .suggestions {
        flex-basis: 40%;
        /*border-right: 1px solid #D8D8D8;*/
        text-align: left;
        padding: 10px;
        overflow-y: scroll; }
      .header .search-container .suggestions-container .recommendation {
        flex-basis: 60%;
        text-align: left;
        padding: 10px;
        overflow-y: scroll; }
      .header .search-container .suggestions-container .title {
        font-weight: bold;
        color: #6a6a6a;
        font-size: 13px; }
      .header .search-container .suggestions-container .title-container {
        border-bottom: 1px solid;
        border-color: #d8d8d8; }
      .header .search-container .suggestions-container .recommendation_add_qty {
        width: 50px;
        border: 1px solid #ccc;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0px;
        height: 30px; }
      .header .search-container .suggestions-container .typeahead-text {
        color: #6a6a6a;
        font-size: 13px;
        margin: 0px;
        padding: 5px 0px;
        cursor: pointer; }
        .header .search-container .suggestions-container .typeahead-text:hover {
          background-color: #f8f8f8;
          font-weight: bold; }
      .header .search-container .suggestions-container .recommended-item {
        border-bottom: 1px solid #d8d8d8;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .header .search-container .suggestions-container .recommended-item .item-description {
          color: #6a6a6a;
          font-size: 13px;
          flex-basis: 50%; }
        .header .search-container .suggestions-container .recommended-item .item-quantity {
          color: #979797;
          font-size: 10px;
          border: 1px solid #d8d8d8;
          padding: 5px; }
        .header .search-container .suggestions-container .recommended-item button {
          border: 0;
          outline: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          padding: 6px 13px;
          background-color: #00619f;
          color: #fff; }
  .header .react-search-field-input {
    height: 38px !important; }
  .header .react-search-field-button {
    height: 38px !important;
    background-color: #00619f !important;
    color: #fff !important; }
  .header .react-search-field-button svg {
    fill: #fff !important; }
  .header .text_style {
    color: #fff;
    padding-right: 0px;
    font-size: 12px; }
  .header .background-darkblue {
    background-color: #00619f; }
  .header .col:first-child {
    margin-left: 0; }
  .header .span_6_of_12 {
    width: 49.2%; }
  .header .remove-top-bottom {
    margin: 0 0 0 1.6%;
    width: 72% !important; }
  .header .col {
    display: grid;
    float: left; }
  .header .active {
    /* background-color: #0072bc; */
    background-color: #b91e1e;
    padding: 18px 20px !important; }
  .header .inactive {
    background-color: #00619f;
    padding: 18px 20px !important; }
  .header .select-style {
    background: url(/assets/images/arrow-down.png) no-repeat 90% 65%;
    padding-top: 12px;
    padding-left: 12px; }
  .header .select-style select {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    width: 20%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none; }
  .header option {
    font-weight: normal;
    display: block;
    white-space: pre;
    min-height: 1.2em;
    padding: 0px 2px 1px; }

::-webkit-input-placeholder {
  text-transform: capitalize; }

::placeholder {
  text-transform: capitalize; }

.nod-aboutus-div {
  background-color: #f3f3f3; }

.nod-about-page-first-section {
  margin-top: 180px;
  padding: 50px 0px;
  background-color: white; }

.nod-about-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px; }

.nod-about-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: small; }

.nod-history-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
  padding: 20px 0px; }


.copyright-div {
  text-align: center;
  font-size: smaller;
  color: #595959;
  font-weight: 600;
  padding: 10px 0px; }

.main_container_login {
  width: 100%;
  overflow-x: hidden !important; }

body {
  width: 100%;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif !important; }

.content_container {
  margin-bottom: 80px; }

.header_container {
  width: 100%;
  height: 40px;
  background-color: #0072BC; }

.logo_img {
  width: 165px;
  text-align: center; }

.logo_img_container {
  text-align: center;
  margin-top: 25px; }

.login_title_container {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 6px; }

.login_title_text {
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  margin: 1.6% 0 1.6% 0;
  margin-bottom: 6px; }

.login_subtitle_container {
  text-align: center; }

.login_detail_container {
  width: 485px;
  margin: auto;
  border: solid 1px #979797;
  height: auto;
  padding: 40px;
  padding-bottom: 40px; }

input[type=text] {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%;
  margin-bottom: 25px; }

input[type=password] {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%; }

.checkbox_label {
  color: #979797; }

.checkbox_main_container {
  margin-top: 15px;
  margin-bottom: 15px; }

.left_container {
  float: left;
  padding-left: 0px !important;
  text-align: left; }

.checkbox {
  margin-right: 8px; }

.right_container {
  float: right;
  padding-right: 0px !important;
  text-align: right; }

.captcha-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem; }

.login_btn {
  height: 35px;
  background-color: #0072bc;
  border-color: #0072bc;
  color: #fff;
  width: 100%; }
  .login_btn.disabled {
    opacity: 0.5; }

.footer_container {
  width: 100%;
  background-color: #f7f8fb;
  height: auto;
  padding: 15px; }

.col:first-child {
  margin-left: 0; }

.span_3_of_12 {
  width: 23.8%;
  padding-left: 100px; }

.span_3_of_12_1 {
  width: 23.8%; }

.add-top-bottom {
  margin: 3.2% 0 3.2% 1.6%; }

.col {
  display: grid;
  float: left; }

.h3-white {
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 18px;
  color: #292b2c;
  font-weight: 600;
  letter-spacing: 0.3px; }

.footer_logo {
  margin-bottom: 20px; }

.footer_space {
  padding-bottom: 16px !important; }

.dark_img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none; }

.footer_p {
  font-size: 14px;
  color: #687188;
  line-height: 28px;
  margin-bottom: 25px; }

.contact_info > li {
  margin-bottom: 15px;
  list-style: none; }

.contact_info {
  padding: 2px !important; }

.widget_links > li {
  margin-bottom: 15px;
  list-style: none; }

.widget_links {
  padding: 2px !important; }

.widget_links a {
  color: #687188;
  line-height: 28px;
  margin-bottom: 25px; }

.icon_style {
  float: left;
  font-size: 16px;
  margin-top: 4px;
  width: 5%;
  color: #687188; }

.border_line {
  border-bottom-width: 1px;
  color: #f7f8fb;
  width: 98%;
  height: 10px;
  margin: auto;
  border-top: 1px solid #ddd; }

.contact_info p {
  color: #687188;
  line-height: 28px;
  margin-bottom: 25px; }

.footer_space {
  padding-top: 25px;
  padding-bottom: 40px; }

.align_col {
  float: left; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  body {
    width: 100% !important; }
  .contact_detail_container {
    padding-left: 10px !important; }
  .office_detail_container {
    padding-left: 10px !important; }
  .help_detail_container {
    padding-left: 10px !important; }
  .connect_detail_container {
    padding-left: 10px !important; } }


.main_container_forgot_password {
  width: 100%;
  overflow-x: hidden !important; }

.forgot_pass_title_text {
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  margin: 1.6% 0 1.6% 0;
  margin-bottom: 30px; }

.forgot_content_title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 25px; }

::-webkit-input-placeholder {
  color: #979797; }

::placeholder {
  color: #979797; }

.password_container {
  width: 380px;
  border: solid 1px #979797;
  height: auto;
  padding: 14px;
  padding-bottom: 14px;
  float: left; }

body {
  margin: 0px; }

.content-container {
  margin: 40px auto; }

.image1 {
  width: 100%; }

.image2 {
  width: 100%;
  height: 280px; }

.image3 {
  width: 100%; }

.react-multiple-carousel__arrow {
  display: none; }

.owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  min-height: 1px;
  float: left; }

.product_img {
  position: relative;
  overflow: hidden; }

.react-multi-carousel-item {
  width: 310px !important; }

.tab-style1 .nav-tabs, .tab-style2 .nav-tabs {
  border-bottom: 0; }

.justify-content-center {
  justify-content: center !important; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

ul, li, ol {
  margin: 0;
  padding: 0; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs li.nav-item a {
  background-color: transparent;
  border: 0;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 20px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.alignText {
  text-align: left !important; }

.font-weight-light {
  font-weight: light; }

.slider .selected .slide_text {
  -moz-animation-duration: 2s;
  -webkit-animation-duration: 2s;
  -moz-animation-name: slidein-left;
  -webkit-animation-name: slidein-left; }

.carousel-status {
  display: none; }

.text_default {
  color: #0c68b8 !important; }

.slider_text {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  -webkit-animation-name: slideInLeft !important;
          animation-name: slideInLeft !important;
  margin-top: 140px;
  -webkit-animation-delay: 0.5s !important;
          animation-delay: 0.5s !important;
  opacity: 1; }

.slider_text1 {
  text-align: left;
  -webkit-animation-name: slideInLeft !important;
          animation-name: slideInLeft !important;
  -webkit-animation-delay: 0.5s !important;
          animation-delay: 0.5s !important;
  opacity: 1; }

.nav-link {
  display: block; }

.react-tabs__tab-list {
  border-bottom: 0px !important; }

.react-tabs__tab--selected {
  border-color: #fff !important;
  color: blue !important; }

.product_action_box {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all .5s ease-in-out;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.pr_action_btn {
  margin: 0 0 0 10px; }

.product_wrap .pr_action_btn li {
  display: block;
  margin: 10px 0; }

.product_action_box li {
  -webkit-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0; }

.pr_action_btn li {
  position: relative;
  margin: 0px 5px;
  display: inline-block; }

.product_wrap .pr_action_btn li a {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 35px; }

.pr_action_btn li a {
  background-color: #fff;
  font-size: 0;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  width: 37px;
  height: 37px;
  padding: 0;
  display: block;
  text-align: center; }

.product_info {
  padding: 15px; }

.product_wrap .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize; }

.pr_action_btn {
  display: table;
  margin: 0 auto;
  padding: 0; }

.para {
  font-family: sans-serif;
  color: #687188;
  line-height: 28px;
  margin-bottom: 25px; }

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 20px !important;
  cursor: pointer;
  font-weight: bold; }

.product_wrap {
  background-color: #fff;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  margin: 5px; }

.legend {
  opacity: 1; }

.heading_s4 {
  margin-bottom: 15px; }

.card-body {
  background-color: #fff !important; }

.leads {
  color: #687188;
  line-height: 28px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px; }

.carousel .slide .legend {
  opacity: 1 !important;
  width: 50% !important;
  background: #00619F !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  font-size: 22px !important;
  bottom: 352px !important;
  margin-left: 0px !important; }

.shop_now_btn {
  width: 10px;
  border-radius: 6px;
  background: #00619F !important;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  height: 10px; }

.home_about_container {
  margin-top: 60px !important;
  margin-top: 6;
  text-align: center;
  border: 1px solid #dcdcdc;
  padding-bottom: 18px;
  background-color: #f4f4f4 !important;
  width: 94% !important; }

.react-multi-carousel-item {
  width: 310px !important; }

.footer_container1 {
  width: 100%;
  background-color: #FFF1F1;
  height: auto;
  padding: 15px; }

.client_para {
  color: #687188;
  line-height: 28px;
  font-size: 18px; }


.nod-banner-imagee {
  width: 100%;
  height: 100%; }

.nod-banner-iteme {
  margin-top: 154px; }

.nod-about-us-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.nod-about-us-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: smaller; }

.nod-about-us-div {
  padding: 30px 0px; }

.nod-about-us-button-div {
  text-align: center; }

.footer-div {
  background-color: white;
  padding-bottom: 4%; }

.footer-inner-div {
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 40px; }

.nod-logo {
  width: 95%; }

.page-list {
  list-style: none;
  color: #7e7e7e; }

.company-name {
  color: #7e7e7e; }

.hover-pointer {
  cursor: pointer; }

.email-item {
  font-size: 13px;
  margin: auto 0px;
  color: #7e7e7e; }

.new-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center; }

.new-modal-content {
  background-color: #fff;
  margin-top: 170px;
  border-radius: 4px; }

.new-modal-header,
.new-modal-footer {
  padding: 10px;
  text-align: center; }

.new-modal-title {
  margin: 0; }

.new-modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  height: 65vh;
  overflow-y: auto;
  border-radius: 4px; }

.react-pdf__Page {
  margin-top: 10px; }

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px; }

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px; }

.react-pdf__Page__canvas {
  margin: 0 auto; }

.nod-brands-section {
  background-color: #f3f3f3; }

.nod-aboutus-div {
  background-color: #f3f3f3; }

.nod-about-page-first-section {
  margin-top: 180px;
  padding: 50px 0px;
  background-color: white; }

.nod-about-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px; }

.nod-about-text {
  padding-right: 20%;
  color: #717171;
  font-size: small; }

.home-div {
  background-color: #f3f3f3; }

.image-component-div {
  margin-top: 160px; }

.bearing-details-div {
  background-color: #f3f3f3; }

.bearing-details-body-div {
  margin-top: 180px;
  padding: 30px;
  background-color: #f7f7f7; }

.banner-image {
  max-width: 100%;
  width: 100%; }

.about-us-heading {
  color: #0c629f;
  text-align: center; }

.about-us-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171; }

.featured-items-div {
  background-image: linear-gradient(to right top, #0c629f, #105990, #135082, #144774, #143f66);
  padding: 50px 0px; }

.featured-items-header-col {
  padding: 20px; }

.featured-items-header-row {
  border-bottom: white 1px solid; }

.featured-items-body-col {
  padding: 20px; }

.featured-items-body-div {
  margin: 10px 0px;
  width: inherit;
  background-color: white;
  border-radius: 10px; }

.featured-items-img-div {
  border-bottom: 3px #0072bc solid; }

.featured-items-img {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-bottom: 10px; }

.featured-items-name-div {
  padding-top: 10px; }

.featured-items-knowmore-div {
  padding: 10px 0px;
  color: #0072bc;
  cursor: pointer; }

.featured-items-header {
  cursor: pointer;
  text-align: center;
  color: white; }

.featured-items-header-active {
  border-bottom: 2px white solid;
  cursor: pointer;
  text-align: center;
  color: white; }

.bearing-heading {
  text-align: center; }

.back-button-div-end {
  text-align: end; }

.bearing-details-text {
  text-align: center; }

.download-button-div {
  text-align: center; }

.special-bearings-offer-text {
  color: red;
  text-align: center; }

.back-button-div-center {
  text-align: center; }

.company-values-div {
  padding: 50px 0px; }

.company-values-row {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap; }

.company-values-img {
  max-width: 100%; }

.coruosel {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 65%;
  margin: auto; }

.seperator {
  width: 100%; }

.controls-wrapper {
  width: 100%; }

.carousel-wrapper {
  width: 100%; }

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px #665d66; }

.client-logos-div {
  background-color: white;
  padding: 30px 0px; }

.client-logos-row {
  margin: auto;
  width: 65%;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap; }

.client-logos-header {
  margin: auto;
  font-size: 17px;
  color: #505050; }

.footer-div {
  background-color: white;
  padding-bottom: 4%; }

.footer-inner-div {
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 40px; }

.zwz-logo {
  width: 75%; }

.page-list {
  list-style: none;
  color: #7e7e7e; }

.company-name {
  color: #7e7e7e; }

.hover-pointer {
  cursor: pointer; }

.email-item {
  font-size: 13px;
  margin: auto 0px;
  color: #7e7e7e; }

.about-div {
  background-color: #f3f3f3; }

.about-banner-first-section {
  padding: 25px 0px 0px 85px; }

.about-heading {
  text-align: center;
  color: #0c629f; }

.about-text {
  font-size: medium;
  color: #949494; }

.banner-image-about {
  width: 375px;
  height: auto;
  margin-left: 25%; }

.about-banner {
  margin-top: 160px;
  padding: 0px 30px 30px 30px;
  background-color: #f7f7f7; }

.zwz-china-div {
  display: flex;
  height: 250px; }

.zwz-china-first-section {
  width: 75%;
  display: inline; }

.zwz-china-pic {
  width: 100%;
  height: 100%; }

.zwz-china-second-section {
  width: 25%;
  display: inline;
  background-image: linear-gradient(to right top, #0c629f, #105990, #135082, #144774, #143f66); }

.zwz-china-heading {
  margin: 16%;
  color: #fefefe; }

.zwz-china-text {
  font-size: small;
  color: #ecf2f6; }

.joint-venture-div {
  padding: 0px 100px 60px 100px; }

.joint-venture-row {
  padding-top: 50px; }

.venture-icon-pic {
  width: 25%; }

.venture-heading {
  padding-top: 15px;
  color: #206fa7; }

.venture-icon {
  display: block; }

.venture-company-names {
  color: white; }

.venture-company-card {
  background-image: linear-gradient(to right top, #0c629f, #105990, #135082, #144774, #143f66);
  border-radius: 4px;
  min-height: 350px; }

.venture-images {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  background-color: white; }

.venture-name {
  padding: 20px; }

.venture-text {
  font-size: small;
  color: #ecf2f6; }

.know-more-div {
  padding: 20px;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 0px; }

.active-slide {
  height: 15px;
  width: 15px;
  background-color: #11578d;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: 3px; }

.in-active-slide {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: 3px; }

.manufacturing-zones-div {
  padding: 60px 100px 0px 100px; }

.manufacturing-zones-icon {
  width: 25%; }

.zones-heading-div {
  padding-top: 15px; }

.zones-heading {
  color: #206fa7; }

.zones-text {
  font-size: small;
  color: #838383; }

.zones-item-col {
  color: white; }

.zones-background-div {
  background-image: linear-gradient(to right top, #0c629f, #105990, #135082, #144774, #143f66);
  border-radius: 4px;
  height: 100%; }

.zones-items-name {
  padding: 20px; }

.zones-items-text {
  font-size: small;
  color: #ecf2f6; }

.dots-div {
  margin-left: 25%;
  padding-top: 10px;
  text-align: center; }


.content-container .customise_card_body {
  padding-top: 4px;
  padding-bottom: 4px; }

.content-container .cardImg {
  height: 120px; }

.list_price1 {
  text-align: center; }

.add_cart_container {
  border: 1px solid #ddd !important; }

.setInput {
  border: 1px solid #ccc !important;
  border-radius: 6px;
  margin-top: 6px;
  height: 30px;
  margin-left: 6px;
  width: 80px !important;
  height: 30px;
  font-size: 12px;
  text-align: center; }

.product_title_container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 20px;
  width: 100%; }

.icon_container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 20px; }

.product_title_style {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 20px; }

.product_detail_table tr th {
  text-align: center;
  border: 1px solid #ddd;
  font-size: 15px; }

.product_detail_table tr td {
  text-align: center;
  border: 1px solid #ddd;
  font-size: 15px; }



.button {
  border: 0;
  outline: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 13px;
  background-color: #00619f;
  color: #fff; }

.dataTables_filter {
  text-align: right; }

.dataTables_filter label {
  text-align: left; }

.billing_header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 14px; }

.profile_container {
  margin: 12px auto;
  width: 90%;
  text-align: left; }

.set_input {
  border: 1px solid #ced4da !important; }

.address_input1 {
  border: 1px solid #ddd !important;
  font-size: 15px;
  padding: 5px;
  margin-bottom: 4px !important; }



.address_input {
  border: 1px solid #ddd !important;
  font-size: 15px;
  padding: 5px;
  margin-bottom: 18px !important; }

#changeColor {
  background-color: #0072bc !important; }

.delivery_add_row {
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 2px #ccc; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.customise_card_body {
  background-color: #fff !important; }

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #ff0066 !important;
  border-bottom: 7px solid #fff;
  border-left: 7px solid #ff0066 !important;
  border-right: 7px solid #fff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.sorting {
  font-weight: bold; }

.rfq_table table {
  width: 100%; }

.sorting_desc {
  font-weight: bold; }

.loader-contanier {
  position: fixed;
  width: 100%;
  height: 100% 7;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background-color: rgba(177, 172, 172, 0.6);
  top: 0px; }

.dataTables_filter {
  text-align: right; }

.dataTables_filter label {
  text-align: left; }

tbody + thead {
  display: none; }

.dataTables_wrapper .row {
  flex-direction: row-reverse !important; }

.dataTables_length {
  text-align: right !important;
  padding-right: 25px !important; }

.dataTables_filter {
  text-align: left !important; }

.dataTables_length label {
  text-align: left !important; }

table thead tr th {
  font-size: 14px; }

table tbody tr td {
  font-size: 12px; }

.cart-data {
  border-bottom: 2px solid #ddd; }

.cart_data_table tr th {
  font-size: 12px;
  font-weight: bold; }

.cart_data_table tr td {
  font-size: 12px; }

.cart-data td {
  text-align: center; }

.cart-data-header th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center; }

table tbody tr td {
  padding: 8px; }

.checkout_col tr td {
  font-size: 12px;
  font-weight: bold; }

.qty_input {
  border: 1px solid #ddd !important;
  margin-top: 6px; }

.est_total_row {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.address_header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px; }

.modal-content {
  padding: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important; }

.fade {
  transition: opacity 0.15s linear; }

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px); }

.modal-dialog {
  max-width: 385px;
  margin: 90px auto !important; }

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal-content {
  padding: 0px 0px 40px 0px; }

.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 40px 30px 50px 30px;
  text-align: center; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

.modal__close-btn {
  position: absolute;
  top: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent; }

.btn-toolbar:last-child {
  margin-bottom: -10px; }

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center !important;
  margin-bottom: 0; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.pre_login_header {
  width: 100%;
  height: 100px;
  background-color: #00619f !important; }

.pre_login_content_container {
  margin-bottom: 88px; }

@media only screen and (min-width: 320px) and (max-width: 480px) {
  body {
    width: 100% !important; }
  .main_container_signup {
    width: 100% !important; }
  .pre_login_header {
    width: 100% !important;
    height: 70px; }
  .login_detail_container {
    width: 80% !important; } }

.nod_login_header {
  width: 100%;
  height: 62px;
  background-color: #00619f !important; }

.nod_logo_img {
  width: 105px; }

.login_btn1 {
  height: 35px;
  background-color: #0072bc;
  border-color: #0072bc;
  color: #fff;
  width: 100%; }
  .login_btn1.disabled {
    opacity: 0.5; }




.address_input {
  border: 1px solid #ddd !important;
  font-size: 15px;
  padding: 5px;
  margin-bottom: 18px !important; }

#changeColor {
  background-color: #0072bc !important; }

.delivery_add_row {
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 2px #ccc; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.new_card thead {
  display: none; }

.new_card td {
  border: 0px !important; }

.wrapper {
  width: 100%;
  max-width: 96%; }

select, input[type="number"] {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 6px; }

textarea {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%;
  margin-bottom: 15px; }

input[type="text"] {
  margin-bottom: 15px; }

.is_disabled {
  height: 35px;
  color: #fff;
  width: 100%;
  background: #979797;
  border: #979797;
  cursor: no-drop; }

.koyo-page-div {
  background-color: #f3f3f3; }

.koyo-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.koyo-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large; }

.koyo-description-div {
  margin-top: 180px;
  padding: 40px 0px; }

.dpi-page-div {
  background-color: #f3f3f3; }

.dpi-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.dpi-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large; }

.dpi-description-div {
  margin-top: 180px;
  padding: 40px 0px; }

.zwz-page-div {
  background-color: #f3f3f3; }

.zwz-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.zwz-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large; }

.zwz-description-div {
  margin-top: 180px;
  padding: 40px 0px; }

.nod-contactus-div {
  background-color: #f3f3f3; }

.form-div {
  position: relative;
  height: 50px;
  overflow: hidden; }

.form-div input {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  border: none;
  outline: none; }

.form-div textarea {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  border: none;
  outline: none; }

.form-div label {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid black; }

.form-div label::after {
  content: "";
  left: 0px;
  bottom: -1px;
  position: absolute;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid #5fa8d3;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease; }

.form-div input:focus + .label-name .content-name,
.form-div input:valid + .label-name .content-name {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  font-size: 14px;
  color: #5fa8d3; }

.form-div textarea:focus + .label-name .content-name,
.form-div textarea:valid + .label-name .content-name {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  font-size: 14px;
  color: #5fa8d3; }

.form-div input:focus + .label-name::after,
.form-div input:valid + .label-name::after {
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.form-div textarea:focus + .label-name::after,
.form-div textarea:valid + .label-name::after {
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.wzwn-page-div {
  background-color: #f3f3f3; }

.wzwn-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.wzwn-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large; }

.wzwn-description-div {
  margin-top: 180px;
  padding: 40px 0px; }

.iko-page-div {
  background-color: #f3f3f3; }

.iko-page-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold; }

.iko-page-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: large; }

.iko-description-div {
  margin-top: 180px;
  padding: 40px 0px; }

