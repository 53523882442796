.cart-data{
    border-bottom: 2px solid #ddd;
}
.cart_data_table tr th{
    font-size: 12px;
    font-weight: bold;
}
.cart_data_table tr td{
    font-size: 12px;
}

.cart-data td{
    text-align: center
}

.cart-data-header th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
}
table tbody tr td{
	padding: 8px;
}
.checkout_col tr td{
    font-size: 12px;
    font-weight: bold;
}

.qty_input{
	border: 1px solid #ddd !important;
	margin-top: 6px;
}

.est_total_row{
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.address_header{
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.modal-content{
    padding: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal-dialog {
    max-width: 385px;
    margin: 90px auto !important;
}

.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal-content {
    padding: 0px 0px 40px 0px;
}

.modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
   	padding: 40px 30px 50px 30px;
    text-align: center;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

.modal__close-btn {
    position: absolute;
    top: 15px;
    font-size: 14px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
}

.btn-toolbar:last-child {
    margin-bottom: -10px;
}

.modal__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center !important;
    margin-bottom: 0;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

