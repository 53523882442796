.home-div {
  background-color: #f3f3f3;
}
.image-component-div {
  margin-top: 160px;
}
.bearing-details-div {
  background-color: #f3f3f3;
}
.bearing-details-body-div {
  margin-top: 180px;
  padding: 30px;
  background-color: #f7f7f7;
}
