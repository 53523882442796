.client-logos-div {
  background-color: white;
  padding: 30px 0px;
}
.client-logos-row {
  margin: auto;
  width: 65%;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
.client-logos-header {
  margin: auto;
  font-size: 17px;
  color: #505050;
}
