.button{
	    border: 0;
	    outline: 0;
	    text-transform: uppercase;
	    font-size: 12px;
	    font-weight: 700;
	    padding: 6px 13px;
	    background-color: #00619f;
	    color: #fff;
}

.dataTables_filter{
  text-align: right
}

.dataTables_filter label{
  text-align: left
}
