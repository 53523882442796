.pre_login_header{
	width: 100%;
	height: 100px;
	background-color: #00619f !important;
}

.pre_login_content_container{
	margin-bottom: 88px;
}


@media only screen and (min-width:320px) and (max-width:480px){
	body{
		width: 100% !important;
	}

	.main_container_signup{
		width: 100% !important;
	}

	.pre_login_header{
		width: 100% !important;
		height: 70px;
	}

	.login_detail_container{
		width: 80% !important;
	}

}