.about-us-heading {
  color: #0c629f;
  text-align: center;
}

.about-us-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
}
