.main_container_login{
	width: 100%;
	overflow-x: hidden !important;


}

body{
	width: 100%;
	overflow-x: hidden !important;
	font-family: 'Poppins', sans-serif !important;

}

.content_container{
	margin-bottom: 80px;
}

.header_container{
	width: 100%;
	height: 40px;
	background-color: #0072BC;
}

.logo_img{
	width: 165px;
	text-align: center;

}

.logo_img_container{
	text-align: center;
	margin-top: 25px;
}
.login_title_container{
	text-align: center;
	margin-top: 0px;
	margin-bottom: 6px;
}

.login_title_text{
	font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    margin: 1.6% 0 1.6% 0;
    margin-bottom: 6px;
}

.login_subtitle_container{
	text-align: center;
}

.login_detail_container{
	width: 485px;
	margin: auto;
	border: solid 1px #979797;
	height: auto;
	padding: 40px;
	padding-bottom: 40px;
}

input[type=text] {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%;
  margin-bottom: 25px;
}

input[type=password] {
  border: none;
  border-bottom: 1px solid #979797;
  width: 100%;
}

.checkbox_label{
	color: #979797;
}

.checkbox_main_container{
	margin-top: 15px;
	margin-bottom: 15px;

}
.left_container{
	float: left;
	padding-left: 0px !important; 
	text-align: left;
}
.checkbox{
	margin-right: 8px;
}

.right_container{
	float: right;
	padding-right: 0px !important;
	text-align: right;
}
.captcha-container{
	display: flex;
	justify-content: center;
	margin: 1rem 0rem;
}
.login_btn{
	height: 35px;
  	background-color: #0072bc;
  	border-color: #0072bc;
  	color: #fff;
  	width: 100%;
  	&.disabled{
  		opacity: 0.5;
  	}
}

