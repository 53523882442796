.company-values-div {
  padding: 50px 0px;
}
.company-values-row {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}
.company-values-img {
  max-width: 100%;
}
