.footer_container1{
	width: 100%;
	background-color: #FFF1F1;
	height: auto;
    padding: 15px;
}
.client_para{
	color: #687188;
    line-height: 28px;
    font-size: 18px;
}