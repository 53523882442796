.form-div {
  //   width: 50%;
  position: relative;
  height: 50px;
  overflow: hidden;
}

.form-div input {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  border: none;
  outline: none;
}

.form-div textarea {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  border: none;
  outline: none;
}

.form-div label {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid black;
}

.form-div label::after {
  content: "";
  left: 0px;
  bottom: -1px;
  position: absolute;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid #5fa8d3;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease;
}

.form-div input:focus + .label-name .content-name,
.form-div input:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: #5fa8d3;
}

.form-div textarea:focus + .label-name .content-name,
.form-div textarea:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: #5fa8d3;
}

.form-div input:focus + .label-name::after,
.form-div input:valid + .label-name::after {
  transform: translateX(0%);
}

.form-div textarea:focus + .label-name::after,
.form-div textarea:valid + .label-name::after {
  transform: translateX(0%);
}
