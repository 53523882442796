.address_input{
	border: 1px solid #ddd !important;
	font-size: 15px; 
	padding: 5px;
	margin-bottom: 18px !important;
}
#changeColor{
	background-color: #0072bc !important;
}

.delivery_add_row{
	border-bottom: 1px solid #ccc;
	box-shadow: 0px 2px #ccc;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}