.footer-div {
  background-color: white;
  padding-bottom: 4%;
}
.footer-inner-div {
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 40px;
}
.zwz-logo {
  width: 75%;
}
.page-list {
  list-style: none;
  color: #7e7e7e;
}
.company-name {
  color: #7e7e7e;
}
.hover-pointer {
  cursor: pointer;
}
.email-item {
  font-size: 13px;
  margin: auto 0px;
  color: #7e7e7e;
}
