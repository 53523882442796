.bearing-heading {
  text-align: center;
}

.back-button-div-end {
  text-align: end;
}

.bearing-details-text {
  text-align: center;
}

.download-button-div {
  text-align: center;
}

.special-bearings-offer-text {
  color: red;
  text-align: center;
}

.back-button-div-center {
  text-align: center;
}
