.about-banner-first-section {
  padding: 25px 0px 0px 85px;
}
.about-heading {
  text-align: center;
  color: #0c629f;
}
.about-text {
  font-size: medium;
  color: #949494;
}
.banner-image-about {
  width: 375px;
  height: auto;
  margin-left: 25%;
}
.about-banner {
  margin-top: 160px;
  padding: 0px 30px 30px 30px;
  background-color: #f7f7f7;
}
