.billing_header{
	border-bottom: 1px solid #ccc;
	padding-bottom: 14px;
}
.profile_container{
	margin: 12px auto;
	width: 90%;
	text-align: left;
}

.set_input{
	border: 1px solid #ced4da !important;
}
.address_input1 {
    border: 1px solid #ddd !important;
    font-size: 15px;
    padding: 5px;
    margin-bottom: 4px !important;
}