.coruosel {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 65%;
  margin: auto;
}

.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px rgb(102, 93, 102);
}
