.nod-about-us-heading {
  color: #0c629f;
  text-align: center;
  font-weight: bold;
}

.nod-about-us-text {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #717171;
  font-size: smaller;
}

.nod-about-us-div {
  padding: 30px 0px;
}

.nod-about-us-button-div {
  text-align: center;
}
